import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-blue-600">
      <div className="flex flex-wrap items-center justify-between max-w-6xl p-4 mx-auto md:p-6">
        <Link to="/" className="hover:no-underline">
          <div className="flex items-center text-white no-underline">
            <svg className="w-8 h-8 mr-2 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" height="512px" viewBox="0 0 512 512" width="512px" data-name="Layer 1">
              <g><path d="m183.707 290.297h58.589v199.703h-58.589z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"/><path d="m242.3 51.294a29.294 29.294 0 1 0 -58.588 0v44.58h58.588z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"/><path d="m67.633 278.3h287.1a17.627 17.627 0 0 0 10.562-3.539l89.643-67.683a17.531 17.531 0 0 0 0-27.98l-89.638-67.684a17.633 17.633 0 0 0 -10.563-3.54h-287.104a17.551 17.551 0 0 0 -17.533 17.526v135.368a17.549 17.549 0 0 0 17.533 17.532z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"/></g>
            </svg>
            <span className="text-xl font-bold tracking-tight">
              {site.siteMetadata.title}
            </span>
          </div>
        </Link>

        <button
          className="flex items-center block px-3 py-2 text-white border border-white rounded sm:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } sm:block sm:flex sm:items-center w-full sm:w-auto`}
        >
          {[
            {
              route: `/faq/`,
              title: `FAQ`,
            },
            {
              route: `/inzerce/`,
              title: `Inzerce`,
            },
            {
              route: `/pravidla/`,
              title: `Pravidla`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white no-underline sm:inline-block sm:mt-0 sm:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
