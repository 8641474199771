import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-gray-200">
      <Header />

      <main className="flex-1 w-full max-w-6xl px-4 py-8 mx-auto md:px-8 md:py-16">
        {children}
      </main>

      <footer className="bg-blue-800">
        <nav className="flex flex-col-reverse items-center justify-between max-w-6xl p-4 mx-auto text-base md:text-sm md:flex-row md:px-8 md:py-4 text-white">
          <div className="">
            Ikonky z{` `}
            <a className="inline-block font-semibold hover:underline my-4 md:my-0 text-white" href="https://www.flaticon.com/" rel="noopener noreferrer">
              flaticon
            </a>
            <br />
            Ilustrace z{` `}
            <a className="inline-block font-semibold hover:underline my-4 md:my-0 text-white" href="https://undraw.co/illustrations" rel="noopener noreferrer">
              unDraw
            </a>
          </div>
          <div className="">
            <a className="inline-block font-semibold hover:underline my-4 md:my-0 text-white" href="https://www.facebook.com/groups/PCporadna.net/" rel="noopener noreferrer">
              vstoupit do skupiny
            </a>
          </div>
          <div className="">
            <a className="inline-block font-semibold hover:underline my-4 md:my-0 text-white" href="https://www.pcporadna.net/" rel="noopener noreferrer">
              www.pcporadna.net
            </a>
          </div>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
